import React, { useEffect } from 'react';
import { ButtonMain } from 'components';
import { useDispatch } from 'react-redux';
import { httpRequestsOnSuccessActions } from 'store';
import { FindLoad, FindSuccess, useModal } from 'utils';
import { Svg } from 'assets';

export const DeleteModal = ({ onDelete, actionType, title, subTitle }) => {
    const dispatch = useDispatch();
    const { close } = useModal();
    const loading = FindLoad(actionType);
    const success = FindSuccess(actionType);

    useEffect(() => {
        if (success) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(actionType));
        }
    }, [success]);

    return (
        <div className='delete-modal-wrapper'>
            <Svg.CloseSvg
                className='delete-modal-close-button'
                onClick={() => close()}
            />

            <div className='delete-modal-content'>
                <h3 className='delete-modal-content-title'>
                    {title}
                </h3>

                <p className='delete-modal-content-text'>
                    {subTitle}
                </p>

                <div className='delete-modal-content-buttons'>
                    <ButtonMain
                        text='Cancel'
                        cancel
                        onClick={() => close()}
                    />

                    <ButtonMain
                        text='Delete'
                        deleteButton
                        loading={loading}
                        onClick={onDelete}
                        loadStyles={{
                            color: '#FFF',
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

