import {
    signUp,
    logIn,
    logOut,
    getRecoveryLink,
    resetPassword,
    changePassword,
    getMyProfile,
    changeCustomerInfo,
    deleteAccount,
    contactUs, getPaymentMethod, createPaymentMethod, deletePaymentMethod,
} from './auth.action';

export { authReducer } from './auth.reducer';
export { watchAuth } from './auth.saga';

export const authActions = {
    /** Auth Actions */
    signUp,
    logIn,
    logOut,
    getMyProfile,
    getRecoveryLink,
    resetPassword,
    changePassword,
    changeCustomerInfo,
    deleteAccount,
    contactUs,

    // Payment Method
    getPaymentMethod,
    createPaymentMethod,
    deletePaymentMethod,
};
