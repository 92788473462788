import { GET_MY_PROFILE_SUCCESS, GET_PAYMENT_METHOD_SUCCESS } from './auth.types';

const initialState = {
    accessToke: null,
    myProfile: null,
    paymentMethod: null,
};

export const authReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_MY_PROFILE_SUCCESS:
            return {
                ...state,
                myProfile: action.payload,
            };

        case GET_PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                paymentMethod: action.payload,
            };

        default:
            return state;
    }
};
