import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GeneratingReport, OrderSummary, StripeElements } from './fragments';
import { authActions } from 'store';

export const OrderNow = () => {
    const [showStripe, setShowStripe] = useState(false);
    const dispatch = useDispatch();
    const token = localStorage.getItem('access-token');

    useEffect(() => {
        if(token) {
            dispatch(authActions.getPaymentMethod());
        }
    }, []);


    return (
        <div className='order-now-page'>
            {/*<BannerOrderNow />*/}
            <div className='container'>
                <div className='order-now-content'>
                    {showStripe ?
                        <StripeElements
                            setShowStripe={setShowStripe}
                        />
                        :
                        <GeneratingReport
                            setShowStripe={setShowStripe}
                        />
                    }
                    <OrderSummary />
                </div>
            </div>
        </div>
    );
};

