import React, { useEffect } from 'react';
import { DeleteAccount, PastOrders } from './fragments';
import { PaymentMethod } from './fragments/paymentMethod';
import { FadeLoaderC } from '../../components';
import { FindLoad } from '../../utils';
import { authActions, ordersActions } from '../../store';
import { useDispatch } from 'react-redux';

export const Orders = () => {
    const load = FindLoad('GET_ORDERS_REQUEST');
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(ordersActions.getOrders());
        dispatch(authActions.getPaymentMethod())
    }, []);

    return (
        <div className="orders-page">
            <div className="container">
                {load ?
                    <div className='loader-wrapper'>
                       <FadeLoaderC big />
                    </div>
                    :
                    <>
                        <PaymentMethod />
                        <PastOrders />
                        <DeleteAccount />
                    </>
                }
            </div>
        </div>
    );
};

