import React from 'react';
import { useLocation } from 'react-router';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CheckoutForm } from './CheckoutForm';
import { Svg } from 'assets';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PKEY);

export const StripeElements = ({ setShowStripe }) => {
    const location = useLocation();
    const { state } = location;

    return (
        <div className="generate-report-wrapper">
            <div className="generate-report-navigation">
                <Svg.ArrowLeft
                    className="generate-report-navigation-svg"
                    onClick={() => setShowStripe(false)}
                />

                <p className="payment-details">
                    {state?.coupon?.id ? 'Order Confirmation' : 'Payment Details'}
                </p>
            </div>

            {/*{state?.coupon?.id &&*/}
            {/*    <div className="confirmation-block">*/}
            {/*        <div className="check-svg-block">*/}
            {/*            <Svg.CheckCircleSvg className="check-svg" />*/}
            {/*        </div>*/}
            {/*        <p className="confirmation-text">*/}
            {/*            Congratulations! <br />*/}
            {/*            Your coupon has been applied successfully.*/}
            {/*        </p>*/}
            {/*    </div>*/}
            {/*}*/}

            <Elements stripe={stripePromise}>
                <CheckoutForm
                    setShowStripe={setShowStripe}
                />
            </Elements>
        </div>
    );
};

