import {
    SIGN_UP,
    LOG_IN,
    LOG_OUT,
    GET_RECOVERY_LINK,
    CHANGE_PASSWORD_REQUEST,
    RESET_PASSWORD,
    GET_MY_PROFILE,
    CHANGE_CUSTOMER_INFO_REQUEST,
    DELETE_ACCOUNT_REQUEST,
    CONTACT_US,
    GET_PAYMENT_METHOD,
    CREATE_PAYMENT_METHOD, DELETE_PAYMENT_METHOD,
} from './auth.types';

export const signUp = (body) => {
    return {
        type: SIGN_UP,
        payload: { body },
    };
};

export const logIn = (user) => {
    return {
        type: LOG_IN,
        payload: user,
    };
};

export const logOut = () => {
    return {
        type: LOG_OUT,
    };
};

export const getMyProfile = () => {
    return {
        type: GET_MY_PROFILE,
    };
};

export const getRecoveryLink = (email) => {
    return {
        type: GET_RECOVERY_LINK,
        payload: { email },
    };
};

export const resetPassword = (passwords) => {
    return {
        type: RESET_PASSWORD,
        payload: { passwords },
    };
};

export const changePassword = (data) => {
    return {
        type: CHANGE_PASSWORD_REQUEST,
        payload: { data },
    };
};

export const changeCustomerInfo = (id, customerInfo) => {
    return {
        type: CHANGE_CUSTOMER_INFO_REQUEST,
        payload: {
            id,
            customerInfo,
        },
    };
};

export const deleteAccount = (id) => {
    return {
        type: DELETE_ACCOUNT_REQUEST,
        payload: {
            id,
        },
    };
};

export const contactUs = (body) => {
    return {
        type: CONTACT_US,
        payload: { body },
    };
};

//  Payment Method
export const getPaymentMethod = ( ) => {
    return {
        type: GET_PAYMENT_METHOD,
    };
};
export const createPaymentMethod = ( body ) => {
    return {
        type: CREATE_PAYMENT_METHOD,
        payload: { body }
    };
};
export const deletePaymentMethod = (  ) => {
    return {
        type: DELETE_PAYMENT_METHOD,
    };
};
