import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
} from '@stripe/react-stripe-js';
import { ButtonMain, CheckboxMain } from 'components';
import { ordersService } from 'store/orders/orders.service';
import { httpRequestsOnErrorsActions } from 'store';
import { Svg } from 'assets';
import { Radio } from '@mui/material';
import { authService } from '../../../../store/auth/auth.service';

export const CheckoutForm = ({ setShowStripe }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [errors, setErrors] = useState({});
    const [iAccept, setIAccept] = useState(false);
    const [loader, setLoader] = useState(false);
    const [saveAsDefault, setSaveAsDefault] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { state } = location;
    const { paymentMethod } = useSelector((state) => state.auth);
    const [selectedValue, setSelectedValue] = useState(null);
    const token = localStorage.getItem('access-token');

    const handleSubmitByDefault = async () => {
        setLoader(true);
        try {
            const { data } = await ordersService.ordersPost({
                address: state?.address,
                email: state?.email,
                claimNumber: state?.claimNumber,
                pmtMethod: paymentMethod?.pmtMethod,
            });
            navigate(`/report/${data?.id}`);
        } catch (e) {
            if (e?.data?.message === 'Invalid address format or location not found.') {
                dispatch(httpRequestsOnErrorsActions.appendError('ORDER_ADDRESS_FAIL', e?.data?.message));
                setShowStripe(false);
            }
            if (e?.data?.message === 'Cannot generate report for the address entered') {
                dispatch(httpRequestsOnErrorsActions.appendError('ORDER_ADDRESS_FAIL', e?.data?.message));
                setShowStripe(false);
            }
            setErrors(prev => ({
                ...prev,
                els: e.data?.message,
            }));

        } finally {
            setLoader(false);
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoader(true);
        setErrors({});

        if (!stripe || !elements) {
            return;
        }

        try {
            // if (state?.coupon?.id) {
            //   const { data } = await ordersService.ordersPost({
            //     address: state?.address,
            //     email: state?.email,
            //     claimNumber: state?.claimNumber,
            //     coupon: state?.coupon?.code,
            //   });
            //
            //   navigate(`/report/${data?.id}`);
            //
            // } else {

            // if (paymentMethod?.pmtMethod) {
            //     const { data } = await ordersService.ordersPost({
            //         address: state?.address,
            //         email: state?.email,
            //         claimNumber: state?.claimNumber,
            //         pmtMethod: paymentMethod?.pmtMethod,
            //     });
            //     navigate(`/report/${data?.id}`);
            // } else {
                const { error, paymentMethod } = await stripe.createPaymentMethod({
                    type: 'card',
                    card: elements.getElement(CardNumberElement),
                    billing_details: {
                        name: event.target.name.value,
                    },
                });

                if (error) {
                    switch (error.code) {
                        case 'incomplete_number':
                        case 'invalid_number':
                            setErrors(prev => (
                                {
                                    ...prev,
                                    cardNumber: 'Please enter a valid card number.',
                                }
                            ));
                            break;
                        case 'incomplete_expiry':
                        case 'invalid_expiry_year_past':
                        case 'invalid_expiry_year':
                        case 'invalid_expiry_month':
                            setErrors(prev => (
                                {
                                    ...prev,
                                    expiry: 'Please enter a valid expiry date.',
                                }
                            ));
                            break;
                        case 'incomplete_cvc':
                        case 'invalid_cvc':
                            setErrors(prev => (
                                {
                                    ...prev,
                                    cvc: 'Please enter a valid CVV.',
                                }
                            ));
                            break;
                        default:
                            setErrors(prev => ({
                                ...prev,
                                els: error.message,
                            }));
                    }

                } else {
                    if (saveAsDefault) {
                        await authService.createPaymentMethod( { pmtMethod: paymentMethod?.id } )
                    }

                    const { data } = await ordersService.ordersPost({
                        address: state?.address,
                        email: state?.email,
                        claimNumber: state?.claimNumber,
                        pmtMethod: paymentMethod?.id,
                    });

                    navigate(`/report/${data?.id}`);
                }
        } catch (e) {
            if (e?.data?.message === 'Invalid address format or location not found.') {
                dispatch(httpRequestsOnErrorsActions.appendError('ORDER_ADDRESS_FAIL', e?.data?.message));
                setShowStripe(false);
            }

            if (e?.data?.message === 'Cannot generate report for the address entered') {
                dispatch(httpRequestsOnErrorsActions.appendError('ORDER_ADDRESS_FAIL', e?.data?.message));
                setShowStripe(false);
            }

            setErrors(prev => ({
                ...prev,
                els: e.data?.message,
            }));

        } finally {
            setLoader(false);
        }

    };

    return (
        <>

            {paymentMethod?.pmtMethod &&
                <div
                    className="payment-method-box"
                    style={{
                        background: selectedValue === 'default' ? 'rgba(234, 241, 253, 0.50)' : '#FFF',
                        borderColor: selectedValue === 'default' ? '#5C98EC' : '#E4E7EC',
                        marginBottom: '16px',
                    }}
                >
                    <div style={{ width: '100%' }} className="payment-method-card-info"
                    >
                        <div className="flex-start"
                             style={{ gap: 16, color: selectedValue === 'default' ? '#0948A1' : '#152E47' }}>
                            <div>
                                {paymentMethod?.card === 'mastercard' ? <Svg.MasterCard /> : <Svg.VisaCard />}
                            </div>
                            <div>
                                <p className="payment-method-card-title">{`**** **** **** ${paymentMethod?.last4}`}</p>
                                <p className="payment-method-card-date">{`Expiry ${paymentMethod?.exp_month}/${paymentMethod?.exp_year}`}</p>
                                <p className="payment-method-card-default">Default Card</p>
                            </div>
                        </div>
                    </div>
                    <Radio
                        checked={selectedValue === 'default'}
                        onChange={() => setSelectedValue('default')}
                        value="default"
                        name="radio-buttons"
                        style={{ padding: 0 }}
                    />
                </div>
            }


            <form className="checkoutForm-wrapper" onSubmit={handleSubmit}>
                <div className={paymentMethod?.pmtMethod ? 'payment-method-box' : ''}
                     style={{
                         background: selectedValue === 'newCard' ? 'rgba(234, 241, 253, 0.50)' : '#FFF',
                         borderColor: selectedValue === 'newCard' ? '#5C98EC' : '#E4E7EC',
                     }}
                >
                    <div style={{ width: '100%' }}>

                        {paymentMethod?.pmtMethod &&
                            <div className="space-between" style={{ alignItems: 'flex-start' }}>
                                <div className="add-new-payment-method">
                                    <Svg.CreditCardAdd />
                                    <p>Add new payment method</p>
                                </div>
                                <Radio
                                    checked={selectedValue === 'newCard'}
                                    onChange={() => setSelectedValue('newCard')}
                                    value="newCard"
                                    name="radio-buttons"
                                    style={{ padding: 0 }}
                                />
                            </div>
                        }

                        {!state?.coupon?.id &&
                            <>
                                <div className="cardholder-block">
                                    <label className="checkoutForm-label">
                                        Card Holder Name:
                                        <input
                                            name="name"
                                            type="text"
                                            placeholder="Name Surname"
                                            required
                                            className="checkoutForm-input"
                                        />
                                    </label>

                                    <p className="card-errors">{errors?.name}</p>
                                </div>

                                <div className="credit-card-number">
                                    <label className="checkoutForm-label">
                                        Credit Card Number:
                                        <CardNumberElement
                                            options={{
                                                style: {
                                                    base: {
                                                        fontSize: '16px',
                                                        color: '#152E47',
                                                        '::placeholder': {
                                                            color: '#aab7c4',
                                                        },
                                                    },
                                                    invalid: {
                                                        color: 'red',
                                                    },
                                                },
                                                classes: {
                                                    base: 'checkoutForm-input',
                                                    invalid: 'checkoutForm-input-error',
                                                },
                                                placeholder: 'xxxx xxxx xxxx xxxx',
                                            }}
                                        />
                                        <p className="card-errors">{errors?.cardNumber}</p>
                                    </label>
                                </div>

                                <div className="expiry-date-cvv">
                                    <div className="credit-card-expiry-date">
                                        <label className="checkoutForm-label">
                                            Expiry Date:
                                            <CardExpiryElement options={{
                                                style: {
                                                    base: {
                                                        fontSize: '16px',
                                                        color: '#152E47',
                                                        '::placeholder': {
                                                            color: '#aab7c4',
                                                        },
                                                    },
                                                    invalid: {
                                                        color: '#1e2446',
                                                    },
                                                },
                                                classes: {
                                                    base: 'checkoutForm-input',
                                                    invalid: 'checkoutForm-input-error',
                                                },
                                                placeholder: 'MM / YY',
                                            }} />
                                        </label>

                                        <p className="card-errors">{errors?.expiry}</p>
                                    </div>

                                    <div className="credit-card-cvv">
                                        <label className="checkoutForm-label">
                                            CVV:
                                            <CardCvcElement options={{
                                                style: {
                                                    base: {
                                                        fontSize: '16px',
                                                        color: '#152E47',
                                                        '::placeholder': {
                                                            color: '#aab7c4',
                                                        },
                                                    },
                                                    invalid: {
                                                        color: '#1e2446',
                                                    },
                                                },
                                                classes: {
                                                    base: 'checkoutForm-input',
                                                    invalid: 'checkoutForm-input-error',
                                                },
                                                placeholder: 'XXX',
                                            }} />
                                        </label>

                                        <p className="card-errors">{errors?.cvc}</p>
                                    </div>
                                </div>
                                {errors?.els &&
                                    <p className="card-errors">{errors?.els}</p>
                                }
                            </>
                        }

                        {token &&
                        <div className="checkbox-text-block">
                            <CheckboxMain
                                checked={saveAsDefault}
                                onChange={() => {
                                    setSaveAsDefault(prev => !prev);
                                }}
                            />
                            <p className="checkbox-text">
                                Save as default
                            </p>
                        </div>
                        }
                    </div>
                </div>

                <div className="checkbox-text-block" style={{ marginTop: '24px' }}>
                    <CheckboxMain
                        checked={iAccept}
                        onChange={() => {
                            setIAccept(prev => !prev);
                        }}
                    />

                    <p className="checkbox-text">
                        I accept the
                        <NavLink
                            to="/terms"
                            className="checkbox-text-link"
                            target="_blank"
                        >
                            ClaimFRV Terms of Service.
                        </NavLink>
                    </p>

                </div>

                {selectedValue !== 'default' &&
                    <ButtonMain
                        type="submit"
                        text="Generate Report"
                        className="generate-report-button"
                        disabled={(!stripe || !iAccept)}
                        loading={loader}
                    />
                }
            </form>

            {selectedValue === 'default' &&
                <ButtonMain
                    type="button"
                    text="Generate Report"
                    className="generate-report-button"
                    disabled={(!iAccept)}
                    loading={loader}
                    onClick={handleSubmitByDefault}
                />
            }

            <p className="generate-report-under-text">
                Review your order details before finalizing your purchase. Ensure the accuracy of
                the property address and chosen options.
            </p>

        </>
    );
};