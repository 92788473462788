import { Svg } from 'assets';
import React from 'react';
import { AddCard } from './addCard';
import { useModal } from 'utils';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteModal } from 'fragments';
import { authActions } from 'store';

const ACTION_TYPE = 'DELETE_PAYMENT_METHOD'
export const PaymentMethod = () => {
    const { openModal } = useModal();
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PKEY);
    const { paymentMethod } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const addCardModal = () => {
        openModal(
            <Elements stripe={stripePromise}>
                <AddCard />
            </Elements>,
        );
    };

    return (
        <div className="payment-method-wrapper">
            <h2 className="orders-page-title" style={{ marginBottom: 8 }}>Payment Method</h2>
            <p className="orders-page-subtitle">Manage your default payment method for future payments.</p>

            <div className="payment-method-card-box">
                {paymentMethod?.pmtMethod ?
                    <div style={{ width: '100%' }} className='payment-method-card-info'>
                        <div className="flex-start" style={{ gap: 16 }}>
                            <div>
                                {paymentMethod?.card === 'mastercard' ? <Svg.MasterCard /> : <Svg.VisaCard />}
                            </div>
                            <div>
                                <p className="payment-card-number">{`**** **** **** ${paymentMethod?.last4}`}</p>
                                <p className="payment-card-date">{`Expiry ${paymentMethod?.exp_month}/${paymentMethod?.exp_year}`}</p>
                                <p className="payment-card-default">Default Card</p>
                            </div>
                        </div>

                        <div className="flex-end" style={{ gap: 8 }}>
                            <button className="edit-card-btn" onClick={addCardModal}>
                                <Svg.EditGray />
                                <p>Edit</p>
                            </button>
                            <button className="delete-card-btn" onClick={() =>
                                openModal(
                                    <DeleteModal
                                        title={'Delete Payment Method'}
                                        subTitle={'Are you sure you want to delete your  card ?'}
                                        actionType={ACTION_TYPE}
                                        onDelete={()=> dispatch(authActions.deletePaymentMethod())}
                                    />,
                                )
                            }>
                                <Svg.RedTrash />
                                <p>Delete</p>
                            </button>
                        </div>
                    </div>
                    :
                    <div className="add-card-box" onClick={addCardModal}>
                        <Svg.AddCard />
                        <p>Add Card</p>
                    </div>
                }
            </div>

            <div className="we-accepted-box">
                <p>We Accept</p>
                <Svg.MasterCard />
                <Svg.VisaCard />
            </div>

        </div>
    );
};