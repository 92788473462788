import { call, put, takeLatest } from 'redux-saga/effects';
import { authService } from './auth.service';
import {
    SIGN_UP,
    LOG_IN,
    LOG_OUT,
    GET_RECOVERY_LINK,
    RESET_PASSWORD,
    CHANGE_PASSWORD_REQUEST,
    GET_MY_PROFILE,
    GET_MY_PROFILE_SUCCESS,
    CHANGE_CUSTOMER_INFO_REQUEST,
    DELETE_ACCOUNT_REQUEST,
    CONTACT_US,
    GET_PAYMENT_METHOD,
    GET_PAYMENT_METHOD_SUCCESS, CREATE_PAYMENT_METHOD, DELETE_PAYMENT_METHOD,
} from './auth.types';
import { httpRequestsOnErrorsActions } from '../http_requests_on_errors';
import { httpRequestsOnLoadActions } from '../http_requests_on_load';
import { httpRequestsOnSuccessActions } from '../http_requests_on_success';


function* signUp({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(authService.signUp, payload.body);
        yield put({
            type: LOG_IN,
            payload: { email: payload.body.email, password: payload.body.password, },
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data.message));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* logIn({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        const res = yield call(authService.signIn, payload);
        localStorage.setItem('access-token', res.data.token);

        yield put({
            type: GET_MY_PROFILE,
        });

        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* logOut({ type }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));

    try {
        yield call(authService.logOut);
        yield put(httpRequestsOnLoadActions.removeLoading(type));

        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    } finally {
        window.location.replace('/');
        localStorage.removeItem('access-token');
        localStorage.removeItem('userInfo');
    }
}

function* getLink({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(authService.getLink, payload.email);
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.appendError(type, err.data.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    }
}

function* resetPassword(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    try {
        const res = yield call(authService.resetPass, action?.payload?.passwords);
        localStorage.setItem('access-token', res?.data?.token);
        const info = yield call(authService.myProfileService);
        localStorage.setItem('userInfo', JSON.stringify(info?.data));

        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    } catch (err) {
        yield put(
            httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message),
        );
        yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    }
}

function* changePassword(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        const res = yield call(authService.changePasswordService, action.payload.data);
        localStorage.setItem('access-token', res?.data?.token);

        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.removeError(action.type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
        yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    }
}

function* changeCustomerInfo(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        yield call(authService.changeCustomerService, action.payload.id, action.payload.customerInfo);
        yield put({
            type: GET_MY_PROFILE,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.removeError(action.type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(action.type, err?.data?.message));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    }
}

function* getMyProfile(action) {
    yield put(httpRequestsOnErrorsActions.removeError(action.type));
    yield put(httpRequestsOnLoadActions.appendLoading(action.type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(action.type));
    try {
        const info = yield call(authService.myProfileService);
        localStorage.setItem('userInfo', JSON.stringify(info?.data));
        yield put({
            type: GET_MY_PROFILE_SUCCESS,
            payload: info.data,
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(action.type));
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
        yield put(httpRequestsOnErrorsActions.removeError(action.type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(action.type));
    }
}

function* deleteAccount({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));

    try {
        yield call(authService.deleteAccountService, payload?.id);

        localStorage.removeItem('access-token');
        localStorage.removeItem('userInfo');

        window.location.replace('/');

        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* contactUs({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));
    try {
        yield call(authService.mailerContactUs, payload.body);
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data.message));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

// Payment Method
function* getPaymentMethod({  type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(authService.getPaymentMethodService);
        yield put({
            type: GET_PAYMENT_METHOD_SUCCESS,
            payload: res?.data,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data.message));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* createPaymentMethod({  type, payload,  }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));

    try {
        yield call(authService.createPaymentMethod, payload?.body);
        yield put({
            type: GET_PAYMENT_METHOD,
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data.message));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* deletePaymentMethod({  type  }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    yield put(httpRequestsOnSuccessActions.removeSuccess(type));

    try {
        yield call(authService.deletePaymentMethodService);
        yield put({
            type: GET_PAYMENT_METHOD,
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data.message));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}


export const watchAuth = function* watchUserAuth() {
    yield takeLatest(SIGN_UP, signUp);
    yield takeLatest(LOG_IN, logIn);
    yield takeLatest(LOG_OUT, logOut);
    yield takeLatest(GET_MY_PROFILE, getMyProfile);
    yield takeLatest(GET_RECOVERY_LINK, getLink);
    yield takeLatest(RESET_PASSWORD, resetPassword);
    yield takeLatest(CHANGE_PASSWORD_REQUEST, changePassword);
    yield takeLatest(CHANGE_CUSTOMER_INFO_REQUEST, changeCustomerInfo);
    yield takeLatest(DELETE_ACCOUNT_REQUEST, deleteAccount);
    yield takeLatest(CONTACT_US, contactUs);

    // Payment Method
    yield takeLatest(GET_PAYMENT_METHOD, getPaymentMethod);
    yield takeLatest(CREATE_PAYMENT_METHOD, createPaymentMethod);
    yield takeLatest(DELETE_PAYMENT_METHOD, deletePaymentMethod);
};
