import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
} from '@stripe/react-stripe-js';
import { ButtonMain } from 'components';
import { ordersService } from 'store/orders/orders.service';
import { authActions, httpRequestsOnSuccessActions } from 'store';
import { FindLoad, FindSuccess, useModal } from 'utils';

const ACTION_TYPE = 'CREATE_PAYMENT_METHOD';
export const AddCard = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [errors, setErrors] = useState({});
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { state } = location;
    const success = FindSuccess(ACTION_TYPE);
    const load = FindLoad(ACTION_TYPE);

    const { close } = useModal();

    useEffect(() => {
        if (success) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [success]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoader(true);
        setErrors({});

        if (!stripe || !elements) {
            return;
        }

        try {
            if (state?.coupon?.id) {
                const { data } = await ordersService.ordersPost({
                    address: state?.address,
                    email: state?.email,
                    claimNumber: state?.claimNumber,
                    coupon: state?.coupon?.code,
                });

                navigate(`/report/${data?.id}`);

            } else {
                const { error, paymentMethod } = await stripe.createPaymentMethod({
                    type: 'card',
                    card: elements.getElement(CardNumberElement),
                    billing_details: {
                        name: event.target.name.value,
                    },
                });

                if (error) {
                    switch (error.code) {
                        case 'incomplete_number':
                        case 'invalid_number':
                            setErrors(prev => (
                                {
                                    ...prev,
                                    cardNumber: 'Please enter a valid card number.',
                                }
                            ));
                            break;
                        case 'incomplete_expiry':
                        case 'invalid_expiry_year_past':
                        case 'invalid_expiry_year':
                        case 'invalid_expiry_month':
                            setErrors(prev => (
                                {
                                    ...prev,
                                    expiry: 'Please enter a valid expiry date.',
                                }
                            ));
                            break;
                        case 'incomplete_cvc':
                        case 'invalid_cvc':
                            setErrors(prev => (
                                {
                                    ...prev,
                                    cvc: 'Please enter a valid CVV.',
                                }
                            ));
                            break;
                        default:
                            setErrors(prev => ({
                                ...prev,
                                els: error.message,
                            }));
                    }

                } else {
                    dispatch(authActions.createPaymentMethod({ pmtMethod: paymentMethod?.id }));
                }
            }

        } catch (e) {
            setErrors(prev => ({
                ...prev,
                els: e.data?.message,
            }));

        } finally {
            setLoader(false);
        }

    };

    return (
        <form className="simple-modal-box" onSubmit={handleSubmit}>
            <p className="simple-modal-title">Payment Method</p>
            <p className="simple-modal-sub-title">Add card details for future payments.</p>
            {!state?.coupon?.id &&
                <div className="payment-inputs-box">
                    <div className="payment-input-wrapper" style={{ gap: '16px' }}>
                        <div className="payment-card-input-box">
                            <label>
                                <span>Card Holder Name*</span>
                                <input
                                    name="name"
                                    type="text"
                                    placeholder="Name Surname"
                                    required
                                    className="checkoutForm-input"
                                />
                            </label>
                        </div>

                        <div className="payment-card-input-box input-box">
                            <label>
                                <span>Expiry Date:</span>
                                <CardExpiryElement options={{
                                    classes: {
                                        base: 'checkoutForm-input',
                                        invalid: 'checkoutForm-input-error',
                                    },
                                    placeholder: 'MM / YY',
                                }} />
                            </label>
                        </div>
                    </div>

                    <div className="payment-input-wrapper" style={{ gap: '16px' }}>
                        <div className="payment-card-input-box">
                            <label>
                                <span>Credit Card Number:</span>
                                <CardNumberElement
                                    options={{
                                        classes: {
                                            base: 'checkoutForm-input',
                                            invalid: 'checkoutForm-input-error',
                                        },
                                        placeholder: 'xxxx xxxx xxxx xxxx',
                                    }}
                                />
                            </label>
                        </div>

                        <div className="payment-card-input-box input-box">
                            <label className="checkoutForm-label">
                                <span>CVV:</span>
                                <CardCvcElement options={{
                                    classes: {
                                        base: 'checkoutForm-input',
                                        invalid: 'checkoutForm-input-error',
                                    },
                                    placeholder: 'XXX',
                                }} />
                            </label>
                        </div>
                    </div>
                    <p className="card-errors">{errors?.els || errors?.cvc || errors?.cardNumber || errors?.expiry || errors?.name}</p>
                </div>
            }

            <div className="simple-modal-action-buttons">
                <ButtonMain
                    text="Cancel"
                    cancel
                    onClick={() => close()}
                />
                <ButtonMain
                    type={'submit'}
                    text="Save"
                    loading={loader || !!load?.length}
                    loadStyles={{
                        color: '#FFF',
                    }}
                />
            </div>
        </form>
    );
};