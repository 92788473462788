import axios from 'axios';

export const authService = {
    signUp: (body) => axios.post('/customer', body),

    signIn: (body) => axios.post('/authn/signin', body),

    logOut: () => axios.post(`/authn/logout`, {}, { auth: true }),

    getLink: (email) => axios.get(`/authn/forgotPassword/${email}`),

    resetPass: (info) => {
        return axios.post('/authn/resetPassword', {
                newPassword: info.newPassword,
                confirmation: info.confirmation,
            },
            { headers: { 'reset-token': info.token } })
    },

    changePasswordService: (data) => axios.post('/authn/changePassword', data, { auth: true }),

    changeCustomerService: (id, data) => axios.patch(`/customer/${id}`, data, { auth: true }),

    myProfileService: () => axios.get(`/customer/myProfile/byToken`, { auth: true }),

    deleteAccountService: (id) => axios.delete(`/customer/${id}`, { auth: true }),

    mailerContactUs: (body) => axios.post(`/mailer/contactForm`, { ...body }),

    //  Payment Method
    getPaymentMethodService: ( ) => axios.get(`/billing/pmtMethod`, { auth: true }),

    createPaymentMethod: (body) => axios.patch(`/billing`, body, { auth: true }),

    deletePaymentMethodService: () => axios.delete(`/billing/card`, { auth: true }),

};
