import { ReactComponent as Logo } from './images/svg/home/Logo.svg';
import { ReactComponent as TrustedPartner } from './images/svg/home/TrustedPartner.svg';
import { ReactComponent as Configuration } from './images/svg/home/Configuration.svg';
import { ReactComponent as Benefit } from './images/svg/home/Benefit.svg';
import { ReactComponent as Analysis } from './images/svg/home/Analysis.svg';
import { ReactComponent as List } from './images/svg/home/List.svg';
import { ReactComponent as TransactionHistory } from './images/svg/home/TransactionHistory.svg';
import { ReactComponent as Growth } from './images/svg/home/Growth.svg';
import { ReactComponent as Facebook } from './images/svg/Facebook.svg';
import { ReactComponent as Instagram } from './images/svg/Instagram.svg';
import { ReactComponent as Linkedin } from './images/svg/Linkedin.svg';
import { ReactComponent as Messages } from './images/svg/Messages.svg';
import { ReactComponent as Sms } from './images/svg/Sms.svg';
import { ReactComponent as Eye } from './images/svg/Eye.svg';
import { ReactComponent as Lock } from './images/svg/Lock.svg';
import { ReactComponent as Listing } from './images/svg/Listing.svg';
import { ReactComponent as Profile } from './images/svg/Profile.svg';
import { ReactComponent as Stars } from './images/svg/Stars.svg';
import { ReactComponent as BurgerMenu } from './images/svg/BurgerMenu.svg';
import { ReactComponent as CloseSvg } from './images/svg/CloseSvg.svg';
import { ReactComponent as Close } from './images/svg/Close.svg';
import { ReactComponent as HomeSvg } from './images/svg/HomeSvg.svg';
import { ReactComponent as OrderNowSvg } from './images/svg/OrderNowSvg.svg';
import { ReactComponent as DocumentSvg } from './images/svg/DocumentSvg.svg';
import { ReactComponent as SignOutSvg } from './images/svg/SignOutSvg.svg';
import { ReactComponent as UserSvg } from './images/svg/User.svg';
import { ReactComponent as EditSvg } from './images/svg/EditSvg.svg';
import { ReactComponent as Document } from './images/svg/DocumentSvg.svg';
import { ReactComponent as DownloadSvg } from './images/svg/DownloadSvg.svg';
import { ReactComponent as NotFoundSvg } from './images/svg/NotFoundSvg.svg';
import { ReactComponent as NoDataSvg } from './images/svg/NoDataSvg.svg';
import { ReactComponent as ArrowLeft } from './images/svg/ArrowLeft.svg';
import { ReactComponent as ArrowRight } from './images/svg/ArrowRight.svg';
import { ReactComponent as ReportSvg } from './images/svg/ReportSvg.svg';
import { ReactComponent as Download2Svg } from './images/svg/Download2Svg.svg';
import { ReactComponent as LocationSvg } from './images/svg/LocationSvg.svg';
import { ReactComponent as SmsTrackingSvg } from './images/svg/SmsTrackingSvg.svg';
import { ReactComponent as Calendar2Svg } from './images/svg/Calendar2.svg';
import { ReactComponent as HashtagSvg } from './images/svg/HashtagSvg.svg';
import { ReactComponent as GlobalSvg } from './images/svg/GlobalSvg.svg';
import { ReactComponent as CallSvg } from './images/svg/CallSvg.svg';
import { ReactComponent as LogoShort } from './images/svg/LogoShort.svg';
import { ReactComponent as CallCalling } from './images/svg/CallCalling.svg';
import { ReactComponent as CouponSvg } from './images/svg/CouponSvg.svg';
import { ReactComponent as CheckCircleSvg } from './images/svg/CheckCircleSvg.svg';
import { ReactComponent as AddCard } from './images/svg/AddCard.svg';
import { ReactComponent as MasterCard } from './images/svg/MasterCard.svg';
import { ReactComponent as VisaCard } from './images/svg/VisaCard.svg';
import { ReactComponent as EditGray } from './images/svg/EditGray.svg';
import { ReactComponent as RedTrash } from './images/svg/RedTrash.svg';
import { ReactComponent as CreditCardAdd } from './images/svg/CreditCardAdd.svg';
import NoImageTemp  from './images/svg/NoImageTemp.svg';

// Images
import bannerHome from './images/img/home/BannerHome.png'
import WorkWithUs from './images/img/home/WorkWithUs.png'
import SimplePng from './images/img/home/SimplePng.png'
import ReadyBack from './images/img/home/ReadyBack.png'
import LoginImg from './images/img/LoginImg.png'
import BannerOrderNow from './images/img/orderNow/BannerOrderNow.png'
import TempLogo from './images/img/TempLogo.png'

export const Images = {
    bannerHome,
    WorkWithUs,
    SimplePng,
    ReadyBack,
    LoginImg,
    BannerOrderNow,
    TempLogo,
};

export const Svg = {
    CreditCardAdd,
    RedTrash,
    EditGray,
    MasterCard,
    VisaCard,
    AddCard,
    CallCalling,
    Logo,
    TrustedPartner,
    Configuration,
    Benefit,
    Analysis,
    List,
    TransactionHistory,
    Growth,
    Facebook,
    Instagram,
    Linkedin,
    Messages,
    Sms,
    Eye,
    Lock,
    Listing,
    Profile,
    Stars,
    BurgerMenu,
    CloseSvg,
    Close,
    HomeSvg,
    OrderNowSvg,
    DocumentSvg,
    SignOutSvg,
    UserSvg,
    EditSvg,
    Document,
    DownloadSvg,
    NotFoundSvg,
    NoDataSvg,
    ArrowLeft,
    ArrowRight,
    ReportSvg,
    Download2Svg,
    NoImageTemp,
    LocationSvg,
    SmsTrackingSvg,
    Calendar2Svg,
    HashtagSvg,
    GlobalSvg,
    CallSvg,
    LogoShort,
    CouponSvg,
    CheckCircleSvg
};