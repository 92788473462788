import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { DateRow, Loader, PriceRow, TextRow } from 'components';
import { PastOrdersCard } from './pastOrdersCard';
import { useWindowSize } from 'utils';
import { Svg } from 'assets';
import axios from 'axios';
import { NoData } from './noData';

export const PastOrders = () => {
    const [loading, setLoading] = useState(false);
    const { orders } = useSelector((state) => state.orders);
    const [width] = useWindowSize();
    const [currentId, setCurrentId] = useState('');

    const handleDownload = async (item, order) => {
        setCurrentId(order?.id);
        setLoading(true);
        try {
            const response = await axios({
                url: `/files/download`,
                method: 'POST',
                responseType: 'blob',
                data: item,
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const fileIndex = response?.data?.type?.search('/');
            const fileType = response?.data?.type?.slice(fileIndex + 1);
            let filename = item?.file?.originalName ? item?.file?.originalName : `file.${fileType === 'svg+xml' ? 'svg' : fileType}`;

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${filename}`);
            document.body.appendChild(link);
            link.click();

            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
        } finally {
            setCurrentId('');
            setLoading(false);
        }
    };

    return (
        <div className="past-orders-wrapper">
            {orders?.length > 0 ? (
                <div className="past-orders-block">
                    <div className="orders-page-title">Past Orders</div>
                    <div className="flex-all-center">
                        {width > 767 ? (
                            <div className="past-orders-table">
                                <div className="past-orders-table-header">
                                    <div className="past-orders-table-header-row">
                                        <div className="past-orders-table-cell" style={{ width: '40%' }}>
                                            <p className="past-orders-table-header-text">Property Address</p>
                                        </div>
                                        <div className="past-orders-table-cell" style={{ width: '20%' }}>
                                            Date Paid
                                        </div>
                                        <div className="past-orders-table-cell" style={{ width: '20%' }}>
                                            Price
                                        </div>
                                        <div className="past-orders-table-cell" style={{ width: '20%' }}>
                                            Action
                                        </div>
                                    </div>
                                </div>

                                <div className="past-orders-table-body">
                                    {orders?.map((order) => (
                                        <div className="past-orders-table-body-row" key={order?.id}>
                                            <div className="past-orders-table-cell" style={{ width: '40%' }}>
                                                <Svg.DocumentSvg />
                                                <TextRow color="#3D4751" name={order?.address?.formattedAddress} />
                                            </div>
                                            <div className="past-orders-table-cell" style={{ width: '20%' }}>
                                                <DateRow color="#3D4751" date={order?.createdAt} />
                                            </div>
                                            <div className="past-orders-table-cell"
                                                 style={{ width: '20%', padding: '0 4px' }}>
                                                <PriceRow info={order?.paymentAmount ? +order?.paymentAmount : 0} />
                                            </div>
                                            <div className="past-orders-table-cell"
                                                 style={{ width: '20%', padding: '0 10px' }}>
                                                <div className="svg-block">
                                                    <a href={order?.pdfFile?.url} rel="noreferrer" target={'_blank'}>
                                                        <Svg.Eye />
                                                    </a>
                                                </div>
                                                <div className="svg-block svg-margin-left">
                                                    {loading && currentId === order?.id ? (
                                                        <Loader styles={{ color: '#347AF0', width: 18, height: 18 }} />
                                                    ) : (
                                                        <Svg.DownloadSvg
                                                            onClick={() => handleDownload(order?.pdfFile, order)} />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className=" orders-mobile">
                                {orders?.map((order) => (
                                    <PastOrdersCard key={order?.id} order={order}
                                                    handleDownload={() => handleDownload(order?.pdfFile)}
                                                    loading={loading} />
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <NoData />
            )}
        </div>
    );
};
